<template>
  <div class="container">
    <header class="jumbotron">
      <h3>Proveedores</h3>
    </header>
    <div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name {{ content.status }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, key) in content.data" v-bind:key="key">
              <th scope="row">{{value.id}}</th>
              <td>{{value.name}}</td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</template>

<script>
import ProviderService from "../services/provider.service";

export default {
  name: "Provider",
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    if (this.loggedIn) {
      // this.$router.push("/profile");
    }
    ProviderService.getProviders().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>
